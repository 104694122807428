var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('base-material-card',{attrs:{"color":"primary","icon":"mdi-anchor","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2"},[_vm._v(" Fleets ")])]},proxy:true}])},[_c('v-row',{attrs:{"align":"end"}},[_c('v-text-field',{staticClass:"ml-auto mr-3",staticStyle:{"max-width":"160px"},attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"warning","small":""},on:{"click":function($event){_vm.addFleet = true}}},on),[_c('v-icon',{attrs:{"size":"28"}},[_vm._v(" mdi-plus-circle-outline ")])],1)]}}])},[_c('span',[_vm._v("Add Fleet")])])],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.computedHeaders,"items":_vm.fleets,"options":_vm.options,"loading":_vm.loading,"hide-default-footer":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(fleet){return [_c('tr',[_c('td',[_c('router-link',{staticClass:"table-link",attrs:{"to":'/fleets/' + fleet.item.id}},[_vm._v(" "+_vm._s(_vm._f("truncate")(fleet.item.name,42))+" ")])],1),_c('td',[_c('span',[_vm._v(_vm._s(fleet.item.vessels_count))])]),_c('td',[_c('v-speed-dial',{attrs:{"direction":"left"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"fab":"","small":"","color":"primary"},model:{value:(_vm.fab[fleet.item.id]),callback:function ($$v) {_vm.$set(_vm.fab, fleet.item.id, $$v)},expression:"fab[fleet.item.id]"}},[(_vm.fab[fleet.item.id])?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1)]},proxy:true}],null,true),model:{value:(_vm.fab[fleet.item.id]),callback:function ($$v) {_vm.$set(_vm.fab, fleet.item.id, $$v)},expression:"fab[fleet.item.id]"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteFleet(fleet.item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Remove Fleet")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({attrs:{"dark":""}},on),[_c('router-link',{staticClass:"table-link",attrs:{"to":'/fleets/' + fleet.item.id}},[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"success"}},[_c('v-icon',[_vm._v("mdi-eye-check")])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v("View Fleet")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({attrs:{"dark":""}},on),[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"warning"},on:{"click":function($event){_vm.changeFleet=true, _vm.changeContent = fleet.item}}},[_c('v-icon',[_vm._v("mdi-rename-box")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Rename Fleet")])])],1)],1)])]}}])}),_c('table-footer',{attrs:{"options":_vm.options,"total":_vm.total}})],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.addFleet),callback:function ($$v) {_vm.addFleet=$$v},expression:"addFleet"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" New Fleet ")]),_c('v-divider'),_c('v-text-field',{staticClass:"mt-10 mr-5 ml-5 mb-5",attrs:{"color":"secondary","label":"Add New Fleet Name","prepend-icon":"mdi-anchor"},model:{value:(_vm.newFleetData.name),callback:function ($$v) {_vm.$set(_vm.newFleetData, "name", $$v)},expression:"newFleetData.name"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.addFleet = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addNewFleet}},[_vm._v(" Add ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.changeFleet),callback:function ($$v) {_vm.changeFleet=$$v},expression:"changeFleet"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Change Fleet ")]),_c('v-divider'),_c('v-text-field',{staticClass:"mt-10 mr-5 ml-5 mb-5",attrs:{"color":"secondary","label":"Change Fleet Name","prepend-icon":"mdi-anchor"},model:{value:(_vm.changeContent.name),callback:function ($$v) {_vm.$set(_vm.changeContent, "name", $$v)},expression:"changeContent.name"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.changeFleet = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.changeFleetName}},[_vm._v(" Change ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }